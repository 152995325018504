import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import { getOffers, getOffersSuccess, getOffersFailure } from './actions';

import { getReguest } from '../app/api';

function* getOffersSaga(action: any): any {
    try {
        const { payload } = action;

        const { filter } = payload;

        const [posts] = yield all([
            call(getReguest, {
                route:
                    '/api/offers?' +
                    'fields[0]=Title' +
                    '&fields[1]=Description' +
                    '&fields[2]=Slug' +
                    '&fields[3]=IsMaskPreview' +
                    '&fields[4]=IsPartner' +
                    '&populate=Post_category' +
                    '&populate=Preview' +
                    '&populate=Emoji' +
                    filter,
            }),
        ]);

        yield put(
            getOffersSuccess({
                posts,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getOffersFailure(error));
    }
}

export function* offersWatcher() {
    yield takeEvery(getOffers.toString(), getOffersSaga);
}
