import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getOffer,
    getOfferSuccess,
    getOfferFailure,
    getOfferIdle,
} from './actions';

import { Post } from 'types/models';
import { getReguest, getOgReguest } from '../app/api';

function* getSaga(action: any): any {
    try {
        const { payload } = action;

        const { slug } = payload;

        let route =
            '/api/offers/?filters[Slug]=' +
            slug +
            '&fields[0]=Title' +
            '&fields[1]=Lead' +
            '&fields[2]=IsMaskPreview' +
            '&fields[3]=TitleInner' +
            '&fields[4]=OG_Title' +
            '&fields[5]=OG_Description' +
            '&fields[6]=IsPartner' +
            '&populate=Post_category' +
            '&populate=Emoji' +
            '&populate=Preview' +
            '&populate=OG_Image' +
            '&populate=Content' +
            '&pagination[pageSize]=1';

        let [post] = yield all([call(getReguest, { route: route })]);
        if (
            typeof window === 'undefined' &&
            post &&
            post.data &&
            post.data[0] &&
            post.data[0].attributes
        ) {
            const preview = post.data[0].attributes.Preview;
            const emoji = post.data[0].attributes.Emoji;
            const category = post.data[0].attributes.Post_category;
            const isMaskPreview = post.data[0].attributes.IsMaskPreview;
            let image, rubric;
            const text = post.data[0].attributes.Title;
            let request: {
                text: string;
                image?: string;
                rubric?: string;
                mask: boolean;
            } = {
                text: text,
                mask: isMaskPreview,
            };
            if (emoji && emoji.data && emoji.data.attributes) {
                image = emoji.data.attributes.url;
                request = { ...request, image };
            }
            if (
                preview &&
                preview.data &&
                preview.data[0] &&
                preview.data[0].attributes
            ) {
                image = preview.data[0].attributes.url;
                request = { ...request, image };
            }
            if (category && category.data && category.data.attributes) {
                rubric = category.data.attributes.Title;
                request = { ...request, rubric };
            }
            const og = yield call(getOgReguest, request);
            if (og.url) {
                post = { ...post, ogurl: og.url };
            }
        }

        const [posts] = yield all([
            call(getReguest, {
                route:
                    '/api/posts?' +
                    'fields[0]=Title' +
                    '&fields[1]=Background' +
                    '&fields[2]=Description' +
                    '&fields[3]=Slug' +
                    '&fields[4]=IsMaskPreview' +
                    '&populate=Post_category' +
                    '&populate=Preview' +
                    '&populate=Emoji' +
                    '&filters[id][$ne]=100' +
                    '&pagination[start]=0&pagination[limit]=8' +
                    '&sort[0]=SortDate:desc',
            }),
        ]);

        yield put(
            getOfferSuccess({
                post,
                posts,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getOfferFailure(error));
    }
}

export function* offerWatcher() {
    yield takeEvery(getOffer.toString(), getSaga);
}
