import Button from 'components/button';
import './header.css';

import React, { useEffect, useState } from 'react';
import { menu, social } from 'config';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import Footer from 'layout/footer';
import Search from 'layout/search/search';

const Header = (props: any) => {
    const [transparent, setTransparent] = useState(false);

    const history = useHistory();

    const [openNavbar, setOpenNavbar] = useState(false);

    let handleCloseMenu = (e: any) => {
        console.log(
            e.target.classList,
            e.target.parentElement.classList,
            e.target.parentElement.parentElement.classList
        );
        if (
            !e.target.classList.contains('navbar') &&
            !e.target.classList.contains('navbar__menu') &&
            !e.target.classList.contains('header__menu-toggle') &&
            !e.target.parentElement.classList.contains('header__menu-toggle') &&
            !e.target.parentElement.classList.contains('search-form-clear') &&
            !e.target.parentElement.parentElement.classList.contains(
                'search-form-clear'
            ) &&
            !e.target.classList.contains('search-form-input')
        ) {
            if (!props.modalSearch) {
                setOpenNavbar(false);
            }
        }
    };

    useEffect(() => {
        if (history.location.pathname.indexOf('/events/') >= 0) {
            document.body.classList.add('hidden', '_is-page-events-single');
        }
    }, []);

    useEffect(() => {
        if (openNavbar) {
            document.body.classList.add('hidden', '_is-open-navbar');
            document.body.addEventListener('click', handleCloseMenu);
        } else {
            document.body.classList.remove('hidden', '_is-open-navbar');
        }
        return () => {
            document.body.removeEventListener('click', handleCloseMenu);
        };
    }, [openNavbar, props.modalSearch]);

    const location = useLocation();

    useEffect(() => {
        setOpenNavbar(false);
    }, [location])

    return (
        <>
            <div
                className={`header ${
                    (history.location.pathname == '/' && '_transparent') || ''
                } ${(openNavbar && '_active') || ''} ${
                    (props.modalSearch && '_active-search') || ''
                }`}
            >
                <Link to="/" className="header__logo">
                    <svg
                        width="68"
                        height="32"
                        viewBox="0 0 68 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M30.9356 0C31.2332 0 31.485 0.103004 31.691 0.309013C31.897 0.515022 32 0.778255 32 1.09871V30.9356C32 31.2561 31.897 31.5193 31.691 31.7253C31.485 31.9084 31.2332 32 30.9356 32H22.3863C22.0887 32 21.8369 31.9084 21.6309 31.7253C21.4249 31.5193 21.3219 31.2561 21.3219 30.9356V1.09871C21.3219 0.778255 21.4249 0.515022 21.6309 0.309013C21.8369 0.103004 22.0887 0 22.3863 0H30.9356ZM9.5794 0C9.87697 0 10.1288 0.103004 10.3348 0.309013C10.5408 0.515022 10.6438 0.778255 10.6438 1.09871V30.9356C10.6438 31.2561 10.5408 31.5193 10.3348 31.7253C10.1288 31.9084 9.87697 32 9.5794 32H1.06438C0.76681 32 0.515021 31.9084 0.309013 31.7253C0.103004 31.5193 0 31.2561 0 30.9356V1.09871C0 0.778255 0.103004 0.515022 0.309013 0.309013C0.515021 0.103004 0.76681 0 1.06438 0H9.5794ZM18.1288 10.6781C18.4263 10.6781 18.6781 10.7811 18.8841 10.9871C19.0901 11.1931 19.1931 11.4449 19.1931 11.7425V20.2918C19.1931 20.5894 19.0901 20.8412 18.8841 21.0472C18.6781 21.2532 18.4263 21.3562 18.1288 21.3562H13.8712C13.5508 21.3562 13.2876 21.2532 13.0815 21.0472C12.8984 20.8412 12.8069 20.5894 12.8069 20.2918V11.7425C12.8069 11.4449 12.8984 11.1931 13.0815 10.9871C13.2876 10.7811 13.5508 10.6781 13.8712 10.6781H18.1288Z"
                            fill="currentColor"
                        />
                        <path
                            d="M66.9356 21.3219C67.2332 21.3219 67.485 21.4249 67.691 21.6309C67.897 21.8369 68 22.0887 68 22.3863V30.9356C68 31.2332 67.897 31.485 67.691 31.691C67.485 31.897 67.2332 32 66.9356 32H58.3863C58.0887 32 57.8369 31.897 57.6309 31.691C57.4249 31.485 57.3219 31.2332 57.3219 30.9356V27.7082H46.6438V30.9356C46.6438 31.2332 46.5408 31.485 46.3348 31.691C46.1516 31.897 45.8999 32 45.5794 32H37.0644C36.7668 32 36.515 31.897 36.309 31.691C36.103 31.485 36 31.2332 36 30.9356V22.3863C36 22.0887 36.103 21.8369 36.309 21.6309C36.515 21.4249 36.7668 21.3219 37.0644 21.3219H66.9356ZM41.3219 19.1931C41.0014 19.1931 40.7382 19.0901 40.5322 18.8841C40.3491 18.6552 40.2575 18.392 40.2575 18.0944C40.2575 18.0029 40.269 17.9227 40.2918 17.8541L44.5494 0.789701C44.5951 0.537914 44.7096 0.34335 44.8927 0.20601C45.0987 0.0686714 45.3276 0 45.5794 0H62.6438C62.9642 0 63.216 0.103005 63.3991 0.309013C63.6052 0.515022 63.7082 0.766811 63.7082 1.06438V18.1288C63.7082 18.4263 63.6052 18.6781 63.3991 18.8841C63.216 19.0901 62.9642 19.1931 62.6438 19.1931H41.3219Z"
                            fill="currentColor"
                        />
                    </svg>
                </Link>

                <Button
                    className="button header__menu-toggle"
                    attributes={{
                        onClick: () => {
                            if (props.modalSearch) {
                                props.setModalSearch(false);
                            } else {
                                setOpenNavbar(!openNavbar);
                            }
                        },
                    }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </Button>
            </div>
            <div className={`navbar ${(openNavbar && '_active') || ''}`}>
                <div className="navbar__menu">
                    {menu.map((link: any, index: any) => {
                        return (
                            <Link
                                key={index}
                                to={link.href}
                                onClick={e => {
                                    setOpenNavbar(false);
                                }}
                            >
                                {link.title}
                            </Link>
                        );
                    })}
                </div>
                <div className="navbar__footer">
                    <div className="footer _nav">
                        <div className="footer__dev">
                            Сделано в{' '}
                            <a href="https://deareditor.ru" target="_blank">
                                Dears
                            </a>
                        </div>

                        <div className="footer__social">
                            {social.map((link: any, index: any) => {
                                return (
                                    <a href={link.href} key={index}>
                                        {link.title}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="header__search">{props.children}</div>
            </div>
        </>
    );
};

export default Header;
