let token =
    '6ee671f114a05e3296a6ff2cfbd4390d67eba731243ccc8cde2ef00ea5a24a533fa0a18eeb762e1613888d3d3133f146bd6d98030c7256333cfba0857a3f6bde11fd414fc2189a26d7bd7a42f76c55de818b55f1a74d3e11575842984e2f32c3d088c22ac922c014c86b2d41326bdf5c06c228b8da62c7ee563596c036277b1b';

let domain = 'https://api.normdubai.com';
let main_domain = 'https://normdubai.com';

// domain = 'https://ok-dubai.dev.co-dd.dev';
// token =
// 'bbd2c6678f6e4522ded1fb65a5aa9eff7a38c5a989bed387b2f7ada10d554eb8a6bb7e09faca0a7819564687fd05954bda685bd1e524da2bfeead2de4d3d65ecb241618783b5b24a82fb25c39c6616cac4fd5025043db8d27021d9de043c7013e34467cdbd9fc8ae6bea937c9c9da9f997ce00fd170fb77be9cd406902f6ae63';

if (typeof window !== 'undefined') {
    if (
        window.location.hostname.indexOf('9001') >= 0 ||
        window.location.hostname.indexOf('sandbox.linups.online') >= 0
    ) {
        domain = 'https://ok-dubai.dev.co-dd.dev';
        token =
            'bbd2c6678f6e4522ded1fb65a5aa9eff7a38c5a989bed387b2f7ada10d554eb8a6bb7e09faca0a7819564687fd05954bda685bd1e524da2bfeead2de4d3d65ecb241618783b5b24a82fb25c39c6616cac4fd5025043db8d27021d9de043c7013e34467cdbd9fc8ae6bea937c9c9da9f997ce00fd170fb77be9cd406902f6ae63';
    }
}

export const ogImage = 'https://normdubai.com/images/og.png';

export const social = [
    {
        title: 'Instagram',
        href: '#',
    },
    {
        title: 'Facebook',
        href: '#',
    },
    {
        title: 'Linkedin',
        href: '#',
    },
    {
        title: 'Telegram',
        href: '#',
    },
];

export const menu = [
    {
        title: 'Услуги',
        href: '/services',
    },
    {
        title: 'Истории',
        href: '/history',
    },
    {
        title: 'Гайды',
        href: '/history/4',
    },
    {
        title: 'Комьюнити',
        href: '/events',
    },
    {
        title: 'Скидки ❤️',
        href: '/offers',
    },
];

export { domain, token, main_domain };
