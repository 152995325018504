import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getPost,
    getPostSuccess,
    getPostFailure,
    getPostIdle,
} from './actions';

import { Post } from 'types/models';
import { getReguest, getOgReguest } from '../app/api';

function* getSaga(action: any): any {
    try {
        const { payload } = action;

        const { slug } = payload;

        let route =
            '/api/posts/?filters[Slug]=' +
            slug +
            '&fields[0]=Title' +
            '&fields[1]=Lead' +
            '&fields[2]=Background' +
            '&fields[3]=IsMaskPreview' +
            '&fields[4]=TitleInner' +
            '&fields[5]=OG_Title' +
            '&fields[6]=OG_Description' +
            '&fields[7]=IsPartner' +
            '&populate=Post_category' +
            '&populate=Emoji' +
            '&populate=Preview' +
            '&populate=OG_Image' +
            '&populate=Linked_service.Preview' +
            '&populate=Linked_post.Preview' +
            '&populate=Linked_post.Emoji' +
            '&populate=Linked_post.Post_category' +
            '&populate=Content' +
            '&populate=Other_Posts' +
            '&populate=Other_Posts.Preview' +
            '&pagination[pageSize]=1';

        let [post] = yield all([call(getReguest, { route: route })]);
        if (
            typeof window === 'undefined' &&
            post &&
            post.data &&
            post.data[0] &&
            post.data[0].attributes
        ) {
            const preview = post.data[0].attributes.Preview;
            const emoji = post.data[0].attributes.Emoji;
            const category = post.data[0].attributes.Post_category;
            const isMaskPreview = post.data[0].attributes.IsMaskPreview;
            let image, rubric;
            const text = post.data[0].attributes.Title;
            let request: {
                text: string;
                image?: string;
                rubric?: string;
                mask: boolean;
            } = {
                text: text,
                mask: isMaskPreview,
            };
            if (emoji && emoji.data && emoji.data.attributes) {
                image = emoji.data.attributes.url;
                request = { ...request, image };
            }
            if (
                preview &&
                preview.data &&
                preview.data[0] &&
                preview.data[0].attributes
            ) {
                image = preview.data[0].attributes.url;
                request = { ...request, image };
            }
            if (category && category.data && category.data.attributes) {
                rubric = category.data.attributes.Title;
                request = { ...request, rubric };
            }
            const og = yield call(getOgReguest, request);
            if (og.url) {
                post = { ...post, ogurl: og.url };
            }
        }

        yield put(
            getPostSuccess({
                post,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getPostFailure(error));
    }
}

export function* postWatcher() {
    yield takeEvery(getPost.toString(), getSaga);
}
