import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getHomepage,
    getHomepageFailure,
    getHomepageSuccess,
    getPosts,
    getPostsSuccess,
    getPostsFailure,
} from './actions';

import { Post } from 'types/models';
import { getReguest } from '../app/api';

function* getHomepageSaga(): any {
    try {
        let route =
            '/api/Homepage?' +
            // "fields[0]=Posts_title"+
            'populate=Service_categories.Preview' +
            '&populate=Collections_posts.Posts.Preview' +
            '&populate=Collections_posts.Posts.Emoji' +
            '&populate=Collections_posts.Posts.Post_category' +
            '&populate=Collections_posts.Posts_types.Post.Preview' +
            '&populate=Collections_posts.Posts_types.Post.Emoji' +
            '&populate=Collections_posts.Posts_types.Post.Post_category' +
            '&populate=Collections_posts.Posts_types.Post.IsMaskPreview' +
            '&populate=Collections_posts.Posts_types.Post.IsPartner' +
            '&populate=Collections_posts.Posts_types.Offer.Preview' +
            '&populate=Collections_posts.Posts_types.Offer.Emoji' +
            '&populate=Collections_posts.Posts_types.Offer.IsMaskPreview' +
            '&populate=Collections_posts.Posts_types.Offer.IsPartner' +
            '&populate=Collections_posts.Posts_types.Event.Card_background';
        let routeCategories = '/api/post-categories';

        const [homepage, categoriesData] = yield all([
            call(getReguest, { route: route }),
            call(getReguest, { route: routeCategories }),
        ]);

        //console.log(categoriesData);

        // console.log(homepage);

        let topServices: any = homepage.data.attributes.Service_categories.data;
        // let postsTopTitle:any = homepage.data.attributes.Posts_title;
        // let postsTop:any = homepage.data.attributes.Posts.data;
        let collectionsPosts: any =
            homepage.data.attributes.Collections_posts.data;

        let categories = [
            {
                value: '',
                label: 'Все',
                count: 0,
            },
        ];

        if (categoriesData && categoriesData.data) {
            categoriesData.data.map((category: any) => {
                categories.push({
                    value: category.id,
                    label: category.attributes.Title,
                    count: 0,
                });
            });
            let postsWithCategory = yield all(
                categoriesData.data.map((item: any) => {
                    return call(getReguest, {
                        route:
                            '/api/posts?filters[Post_category][id][$in]=' +
                            item.id,
                    });
                })
            );

            let total = 0;

            postsWithCategory.map((item: any, index: any) => {
                categories[index + 1] = {
                    ...categories[index + 1],
                    count: item.data.length,
                };
                total = total + item.data.length;
            });

            categories[0] = {
                ...categories[0],
                count: total,
            };
        }

        yield put(
            getHomepageSuccess({
                topServices,
                // postsTopTitle,
                // postsTop,
                categories,
                collectionsPosts,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getHomepageFailure(error));
    }
}

function* getPostsSaga(action: any): any {
    try {
        const { payload } = action;

        const { filter } = payload;

        const [posts] = yield all([
            call(getReguest, {
                route:
                    '/api/posts?' +
                    'fields[0]=Title' +
                    '&fields[1]=Background' +
                    '&fields[2]=Description' +
                    '&fields[3]=Slug' +
                    '&fields[4]=IsMaskPreview' +
                    '&fields[5]=IsPartner' +
                    '&populate=Post_category' +
                    '&populate=Preview' +
                    '&populate=Emoji' +
                    '&filters[id][$ne]=100' +
                    filter +
                    // '&sort=Sort',
                    // '&sort[0]=Sort:desc'+ +
                    '&sort[0]=SortDate:desc',
            }),
        ]);

        yield put(
            getPostsSuccess({
                posts,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getPostsFailure(error));
    }
}

export function* homepageWatcher() {
    yield takeEvery(getHomepage.toString(), getHomepageSaga);
    yield takeEvery(getPosts.toString(), getPostsSaga);
}
